console.log('Welcome to Authentic Web!');

import 'lite-youtube-embed';
import './scripts/charts.js';



import Alpine from 'alpinejs'
 
window.Alpine = Alpine
 
Alpine.start()