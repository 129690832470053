import Chart from 'chart.js/auto';





if(document.body.contains(document.getElementById('acquisitions'))) {


(async function() {


    const data = {
        labels: [
          'Security Services',
          'IT Infrastructure',
          'Network',
          'Remote Monitoring',
          'Backup & Recovery',
          'Cloud Services',
          'Help Desk',
          'Patch Management',
          'Managed Comms',
          'Compliance',
          'Consulting & Project',
          'Missing MSP Opportunity'
        ],      
        datasets: [{
          label: 'My First Dataset',
          data: [18, 15, 12, 10, 10, 10, 10, 5, 4, 3, 3,0],
          backgroundColor: [
            '#B6EF53',
            '#424242',
            '#76B10D',
            '#9E9E9E',
            '#A1DF35',
            '#71A613',
            '#616161',
            '#3B5C05',
            '#87C913',
            '#5A890F',
            '#757575',
            '#B71C1C',
          ],
          hoverOffset: 1,

        }]
      };

  new Chart(

    document.getElementById('acquisitions'),
    {
        type: 'doughnut',
        data: data,
        options: {
            responsive: true,
            
            plugins: {
                legend: {

                  onClick: null,


                  onHover: (evt, item, legend) => {
                      const chart = legend.chart;
                      const tooltip = chart.tooltip;
            
                      const chartArea = chart.chartArea;
                      tooltip.setActiveElements([{
                        datasetIndex: 0,
                        index: item.index,
                      }], {
                        x: (chartArea.left + chartArea.right) / 2,
                        y: (chartArea.top + chartArea.bottom) / 2,
                      });
            
            
                      chart.update();
                    },


                    display: true,
                    position: 'bottom',
                    align: 'center',
                    labels: {
                        color: '#212121',
                        font: {
                            size: 16,
                            family: "'Inter', sans-serif"
                        },
                        padding: 16,
                    }
                },

                tooltip: {
                    usePointStyle: true,
                    callbacks: {
                        labelPointStyle: function(context) {
                            return {
                                // pointStyle: 'triangle',
                                rotation: 0
                            };
                        },
                        label: function (context) {
                            return  context.formattedValue + "%";
                       }
                    }
                },
                
   
              }
        },

    }
  );
})();


}